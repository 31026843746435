import { useState } from "react";
import { Alert, AlertIcon, Button, CloseButton, Flex, FormControl, FormLabel, Select, Switch, Text } from "@chakra-ui/react";
import { Session as SupabaseSession } from '@supabase/supabase-js'
import { Database } from "../../supabase-schema";
import { supabase } from "../../supabaseClient";
import logEvent from "../../api/logging";

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
type AlertOptions = "info" | "warning" | "success" | "error" | "loading" | undefined
type ReminderFrequency = Database['public']['Enums']['reminder_frequency']
type ReminderDay = Database['public']['Enums']['reminder_day']
type Props = {
    session: SupabaseSession,
    user: Database['public']['Tables']['users']['Row'],
    onSuccess: CallableFunction
};

function isValidDay(day: string): day is ReminderDay {
    switch (day) {
        case 'monday':
        case 'tuesday':
        case 'wednesday':
        case 'thursday':
        case 'friday':
        case 'saturday':
        case 'sunday':
            return true
        default:
            return false
    }
}

const Reminders: React.FC<Props> = ({ session, user, onSuccess }) => {
    const now = new Date()
    const currentDay = WEEKDAYS[((now.getDay() + 6) % 7)].toLowerCase()
    const defaultReminderDay: ReminderDay = isValidDay(currentDay) ? currentDay : 'monday'
    const currentHour = now.getHours()
    const tzOffset = Math.floor(now.getTimezoneOffset() / 60)
    const [isReminderOn, setIsReminderOn] = useState(user.reminder_on ?? true);
    const [reminderFrequency, setReminderFrequency] = useState<ReminderFrequency>(user.reminder_frequency ?? 'daily');
    const [reminderDay, setReminderDay] = useState<ReminderDay>(user.reminder_day ?? defaultReminderDay);
    const [reminderTime1, setReminderTime1] = useState<number>(user.reminder_time1 ? user.reminder_time1 - tzOffset : currentHour);
    const [reminderTime2, setReminderTime2] = useState<number>(user.reminder_time2 ? user.reminder_time2 - tzOffset : 18);
    const [altertType, setAlertType] = useState<AlertOptions>("error")
    const [alert, setAlert] = useState("")
    const [sendingTest, setSendingTest] = useState<boolean>(false)

    const findChangeToLog = () => {
        const change = [
            [user.reminder_on, isReminderOn],
            [user.reminder_frequency, reminderFrequency],
            [user.reminder_day, reminderDay],
            [user.reminder_time1, reminderTime1],
            [user.reminder_time2, reminderTime2]
        ].find(([dbValue, appValue]) => dbValue !== appValue)
        if (change) {
            return ({
                previous_value: String(change[0]),
                current_value: String(change[1])
            })
        } else {
            return {}
        }
    }

    const handleRemindersChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (isReminderOn) {
            try {
                const { error } = await supabase
                    .from('users')
                    .update({ reminder_on: false })
                    .eq('id', user.id)
                if (error) throw error;
            } catch (error: any) {
                console.log(error);
            }
        }
        setIsReminderOn(!isReminderOn)
    }

    const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const { error } = await supabase
                .from('users')
                .update({
                    reminder_on: isReminderOn,
                    reminder_frequency: reminderFrequency,
                    reminder_day: reminderDay,
                    reminder_time1: reminderTime1 + tzOffset,
                    reminder_time2: reminderTime2 + tzOffset,
                })
                .eq('id', user.id)
            if (error) throw error;
            else {
                logEvent({ user_id: user.id, screen: 'reminders', button: 'save', ...findChangeToLog() })
                onSuccess()
            }
        } catch (error: any) {
            console.log(error);
        }
    };

    async function testReminder() {
        setSendingTest(true)
        const { error } = await supabase.functions.invoke('send-email-smtp', {
            body: JSON.stringify({ record: { email: session.user.email } })
        })
        if (error) {
            setAlertType("error")
            setAlert(error.message)
        } else {
            setAlertType("success")
            setAlert("Test reminder sent. Check your inbox or spam folder.")
        }
        setSendingTest(false)
    }

    return (
        <form onSubmit={handleSave} style={{ display: "flex", flexDirection: 'column', gap: '32px' }}>
            <FormControl display="flex" alignItems="center" >
                <FormLabel htmlFor="reminderOn" mb="0">
                    Reminders on/off
                </FormLabel>
                <Switch
                    id="reminderOn" ml="2" size="lg"
                    isChecked={isReminderOn}
                    onChange={handleRemindersChange}
                />
            </FormControl>
            {isReminderOn && <Text as='i'>Reminders are sent to your email according to the following schedule</Text>}
            {isReminderOn && (
                <FormControl display="flex" alignItems="center" flexDirection={['column', 'row']} gap={[2, 0]} >
                    <Button
                        colorScheme={reminderFrequency === 'weekly' ? 'accent' : 'primary'}
                        mr="2" onClick={() => setReminderFrequency('weekly')}>
                        Weekly
                    </Button>
                    <Button
                        colorScheme={reminderFrequency === 'daily' ? 'accent' : 'primary'}
                        mr="2" onClick={() => setReminderFrequency('daily')}>
                        Daily
                    </Button>
                    <Button
                        colorScheme={reminderFrequency === 'twice_a_day' ? 'accent' : 'primary'}
                        onClick={() => setReminderFrequency('twice_a_day')}>
                        Twice a day
                    </Button>
                </FormControl>
            )}
            {isReminderOn && reminderFrequency === 'weekly' && (
                <FormControl>
                    <FormLabel htmlFor="reminderDay">Reminder day</FormLabel>
                    <Select id="reminderDay" value={reminderDay}
                        onChange={(e) => setReminderDay(e.target.value as any)}>
                        {WEEKDAYS.map((day) => (<option key={day} value={day.toLowerCase()}> {day}</option>))}
                    </Select>
                </FormControl>
            )}
            {isReminderOn && (
                <Flex direction='column' gap={10}>
                    <FormControl display="flex" flexDirection={["column", "row"]} justifyContent="space-between" gap={2}>
                        <Flex direction="column">
                            <FormLabel htmlFor="reminderTime1">{reminderFrequency === 'twice_a_day' && "1st "}Reminder time</FormLabel>
                            <Select
                                id="reminderTime1" value={reminderTime1} mr="2"
                                onChange={(e) => setReminderTime1(Number(e.target.value))}>
                                {Array.from(Array(24).keys()).map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour % 12 === 0 ? 12 : hour % 12}:00 {hour < 12 ? 'AM' : 'PM'}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                        {reminderFrequency === 'twice_a_day' &&
                            <Flex direction="column" justifyContent="flex-end">
                                <FormLabel htmlFor="reminderTime2">2nd Reminder time</FormLabel>
                                <Select
                                    id="reminderTime2" value={reminderTime2}
                                    onChange={(e) => setReminderTime2(Number(e.target.value))}>
                                    {Array.from(Array(24).keys()).map((hour) => (
                                        <option key={hour} value={hour}>
                                            {hour % 12 === 0 ? 12 : hour % 12}:00 {hour < 12 ? 'AM' : 'PM'}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>
                        }
                    </FormControl>
                </Flex>
            )}
            {(alert &&
                <Alert status={altertType} position="relative">
                    <AlertIcon />
                    {alert}
                    <CloseButton
                        position="absolute" right={0}
                        onClick={() => { setAlert("") }}
                    />
                </Alert>
            )}
            <Flex justifyContent={isReminderOn ? "space-between" : "flex-end"} wrap='wrap' gap={2}>
                {isReminderOn && <Button isDisabled={sendingTest} onClick={testReminder}>Test reminders</Button>}
                <Button type="submit">
                    Save
                </Button>
            </Flex>
        </form >
    )
};

export default Reminders;
